<template>
  <div class="transportation">
    <h2 class="transportation__title">
      {{ $t('TITLE') }}
    </h2>

    <transportation-results
      v-if="transportation.id"
      class="transportation__results"
    />

    <search-form
      v-else
      class="transportation__search-form"
    />
  </div>
</template>

<script>
import SearchForm from './components/SearchForm'
import TransportationResults from './components/TransportationResults'

import { mapGetters } from 'vuex'

export default {
  name: 'transportation',
  components: {
    SearchForm,
    TransportationResults,
  },

  computed: {
    ...mapGetters('ui/transportation', {
      transportation: 'transportation'
    })
  },

  metaInfo () {
    return {
      title: this.$t('META_TITLE'),
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.transportation {
  padding: 3em 2em;

  &__title {
    @extend %h2;

    text-align: center;
  }
}
</style>

<i18n>
{
  "en": {
    "TITLE": "Transportation",
    "META_TITLE": "Transportation"
  },
  "ka": {
    "TITLE": "ტრანსპორტირება",
    "META_TITLE": "ტრანსპორტირება"
  },
  "ru": {
    "TITLE": "Транспортировка",
    "META_TITLE": "Транспортировка"
  },
  "uk": {
    "TITLE": "Транспортування",
    "META_TITLE": "Транспортування"
  }
}
</i18n>
