<template>
  <div class="transportation-points">
    <div class="transportation-points__block">
      <h3 class="transportation-points__block-title">
        <ui-icon icon="pin" />

        <span class="transportation-points__block-title-text">
          {{ $t('DEPARTURE_TITLE') }}
        </span>
      </h3>

      <div class="transportation-points__block-row">
        <p class="transportation-points__block-lbl">
          {{ $t('ADDRESS_LBL') }}
        </p>

        <p class="transportation-points__block-value">
          {{ departure.address }}
        </p>
      </div>

      <div class="transportation-points__block-row">
        <p class="transportation-points__block-lbl">
          {{ $t('CITY_LBL') }}
        </p>

        <p class="transportation-points__block-value">
          {{ departure.city }}
        </p>
      </div>

      <div class="transportation-points__block-row">
        <p class="transportation-points__block-lbl">
          {{ $t('STATE_LBL') }}
        </p>

        <p class="transportation-points__block-value">
          {{ departure.state }}
        </p>
      </div>

      <div class="transportation-points__block-row">
        <p class="transportation-points__block-lbl">
          {{ $t('COUNTRY_LBL') }}
        </p>

        <p class="transportation-points__block-value">
          {{ formattedDeparture }}
        </p>
      </div>
    </div>

    <div class="transportation-points__block">
      <h3 class="transportation-points__block-title">
        <ui-icon icon="ship" />

        <span class="transportation-points__block-title-text">
          {{ $t('DEPARTURE_HARBOUR_TITLE') }}
        </span>
      </h3>

      <div class="transportation-points__block-row">
        <p class="transportation-points__block-lbl">
          {{ $t('STATE_LBL') }}
        </p>

        <p class="transportation-points__block-value">
          {{ departureHarbour.state }}
        </p>
      </div>

      <div class="transportation-points__block-row">
        <p class="transportation-points__block-lbl">
          {{ $t('COUNTRY_LBL') }}
        </p>

        <p class="transportation-points__block-value">
          {{ formattedDepartureHarbour }}
        </p>
      </div>
    </div>

    <div class="transportation-points__block">
      <h3 class="transportation-points__block-title">
        <ui-icon icon="pin" />

        <span class="transportation-points__block-title-text">
          {{ $t('DESTINATION_TITLE') }}
        </span>
      </h3>

      <div class="transportation-points__block-row">
        <p class="transportation-points__block-lbl">
          {{ $t('CITY_LBL') }}
        </p>

        <p class="transportation-points__block-value">
          {{ destination.city }}
        </p>
      </div>

      <div class="transportation-points__block-row">
        <p class="transportation-points__block-lbl">
          {{ $t('COUNTRY_LBL') }}
        </p>

        <p class="transportation-points__block-value">
          {{ formattedDestination }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'

import { mapGetters } from 'vuex'
import get from 'lodash/get'

import { formatLocation } from 'Utils/formatLocation'

export default {
  name: 'transportation-points',
  components: {
    UiIcon
  },

  computed: {
    ...mapGetters('ui/transportation', {
      transportation: 'transportation'
    }),

    departure () {
      return get(this.transportation, 'relationships.departure', {})
    },

    departureHarbour () {
      return get(this.transportation, 'relationships.departureHarbour', {})
    },

    destination () {
      return get(this.transportation, 'relationships.destination', {})
    },

    formattedDeparture () {
      return formatLocation(this.departure.country)
    },

    formattedDepartureHarbour () {
      return formatLocation(this.departureHarbour.country)
    },

    formattedDestination () {
      return formatLocation(this.destination.country)
    },
  },
}
</script>

<style lang="scss" scoped>
.transportation-points {
  margin: 2.2em -1em;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__block {
    padding: 2em;
    box-shadow: $static-box-shadow;
    border-radius: 5px;
    min-width: 24em;
    margin: 1em;
    opacity: 0;
    animation: fade-in 0.3s ease-in both;

    &:nth-child(1) { animation-delay: 0s; }
    &:nth-child(2) { animation-delay: 0.1s; }
    &:nth-child(3) { animation-delay: 0.2s; }

    &-title {
      @extend %h3;

      text-align: center;
      margin-bottom: 1.6em;
      display: flex;
      align-items: center;
      justify-content: center;

      &-text {
        margin-left: 0.4em;
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
      line-height: 2em;
      padding: 0.4em 0;

      &:not(:last-child) {
        border-bottom: 1px solid $color-light-grey;
      }
    }

    &-lbl {
      color: $color-ui-secondary;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateX(2em);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>

<i18n>
{
  "en": {
    "DEPARTURE_TITLE": "Departure",
    "DEPARTURE_HARBOUR_TITLE": "Departure Harbour",
    "DESTINATION_TITLE": "Destination",
    "ADDRESS_LBL": "Address",
    "CITY_LBL": "City",
    "STATE_LBL": "State",
    "COUNTRY_LBL": "Country"
  },
  "ka": {
    "DEPARTURE_TITLE": "მდებარეობა",
    "DEPARTURE_HARBOUR_TITLE": "გაშვების პორტი",
    "DESTINATION_TITLE": "დანიშნულება",
    "ADDRESS_LBL": "მისამართი",
    "CITY_LBL": "ქალაქი",
    "STATE_LBL": "რეგიონი",
    "COUNTRY_LBL": "ქვეყანა"
  },
  "ru": {
    "DEPARTURE_TITLE": "Отправлениe",
    "DEPARTURE_HARBOUR_TITLE": "Порт отправления",
    "DESTINATION_TITLE": "Место назначения",
    "ADDRESS_LBL": "Адрес",
    "CITY_LBL": "Город",
    "STATE_LBL": "Штат",
    "COUNTRY_LBL": "Страна"
  },
  "uk": {
    "DEPARTURE_TITLE": "Відправлення",
    "DEPARTURE_HARBOUR_TITLE": "Порт відправлення",
    "DESTINATION_TITLE": "Місце призначення",
    "ADDRESS_LBL": "Адреса",
    "CITY_LBL": "Місто",
    "STATE_LBL": "Штат",
    "COUNTRY_LBL": "Країна"
  }
}
</i18n>
