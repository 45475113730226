import { render, staticRenderFns } from "./SearchForm.vue?vue&type=template&id=c5310d44&scoped=true&"
import script from "./SearchForm.vue?vue&type=script&lang=js&"
export * from "./SearchForm.vue?vue&type=script&lang=js&"
import style0 from "./SearchForm.vue?vue&type=style&index=0&id=c5310d44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5310d44",
  null
  
)

/* custom blocks */
import block0 from "./SearchForm.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports