<template>
  <div class="lot-details">
    <h3 class="lot-details__title">
      <span class="lot-details__title-text">
        {{ lotDetails.extendedName || lotDetails.lotName }}
      </span>

      <a
        class="lot-details__title-link"
        :href="lotLink"
        target="_blank"
        :title="$t('LOT_LINK_MSG')"
      >
        <ui-button
          class="lot-details__title-link-btn"
          fill="none"
          look="waiting"
        >
          <ui-icon icon="link" />
        </ui-button>
      </a>
    </h3>

    <div class="lot-details__info">
      <div class="lot-details__info-block">
        <p class="lot-details__info-block-lbl">
          {{ $t('LOT_ID_LBL') }}
        </p>

        <p class="lot-details__info-block-val">
          {{ lot.id }}
        </p>
      </div>

      <div class="lot-details__info-block">
        <p class="lot-details__info-block-lbl">
          {{ $t('LOT_TYPE_LBL') }}
        </p>

        <p class="lot-details__info-block-val">
          {{ lotTypeTranslated }}
        </p>
      </div>

      <div class="lot-details__info-block">
        <p class="lot-details__info-block-lbl">
          {{ $t('END_TIME_LBL') }}
        </p>

        <p class="lot-details__info-block-val">
          <ui-countdown
            class="leasing-card__countdown"
            :start="(new Date(lot.startTime)).getTime()"
            :end="(new Date(lot.endsAt)).getTime()"
            :ended-at="(new Date(lot.endedAt)).getTime()"
            :text-days="$t('COMMON.DAYS')"
            :text-hours="$t('COMMON.HOURS')"
            :text-minutes="$t('COMMON.MINUTES')"
            :locale="$i18n.locale"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { UiButton, UiIcon, UiCountdown } from '@shelf.network/ui-kit'
import { lotTypes } from '@shelf.network/js-sdk'

import { mapGetters } from 'vuex'
import get from 'lodash/get'

import { userGetters } from 'Store/entities/User/types'

export default {
  name: 'lot-details',
  components: {
    UiButton,
    UiIcon,
    UiCountdown
  },

  computed: {
    ...mapGetters('entities/user', { platform: userGetters.PLATFORM }),
    ...mapGetters('ui/transportation', { lot: 'lot' }),

    lotDetails () {
      return get(this.lot, 'details', {})
    },

    lotTypeTranslated () {
      let res = ''
      switch (this.lot.lotType) {
        // the `this.lot` contains raw lot, thus use `lotTypes` of js-sdk
        case lotTypes.sale: res = this.$t('DIRECT_SALE_LOT_TYPE'); break
        case lotTypes.shelfAuction: res = this.$t('SHELF_LOT_TYPE'); break
        case lotTypes.copartAuction: res = this.$t('COPART_LOT_TYPE'); break
        case lotTypes.iaaiAuction: res = this.$t('IAAI_LOT_TYPE'); break
        case lotTypes.bnp: res = this.$t('BTN_LOT_TYPE'); break
      }
      return res
    },

    lotLink () {
      return `${this.platform.clientUrl}/lot/${this.lot.id}`
    },
  }
}
</script>

<style lang="scss" scoped>
.lot-details {
  margin-top: 3.2em;
  padding: 1.6em;
  border-radius: 5px;
  box-shadow: $static-box-shadow;

  &__title {
    @extend %h3;

    text-align: center;

    &-link {
      &-btn {
        line-height: inherit;
        font-size: inherit;

        /deep/ .ui-button__button {
          padding: 0;
          line-height: inherit;
        }
      }
    }
  }

  &__info {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 1.6em;

    &-block {
      text-align: center;
      line-height: 1.8em;
      min-width: 20em;

      &-lbl {
        color: $color-ui-secondary;
      }

      &-val {
        /deep/ .ui-countdown {
          line-height: inherit;
          font-size: 1em;
        }
      }
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DIRECT_SALE_LOT_TYPE": "Direct Sale",
    "SHELF_LOT_TYPE": "Shelf Auction",
    "COPART_LOT_TYPE": "Copart Auction",
    "IAAI_LOT_TYPE": "IAAI Auction",
    "BTN_LOT_TYPE": "BNP auction",
    "LOT_ID_LBL": "ID",
    "LOT_TYPE_LBL": "Type",
    "END_TIME_LBL": "End Time",
    "LOT_LINK_MSG": "Lot Link"
  },
  "ka": {
    "DIRECT_SALE_LOT_TYPE": "პირდაპირი გაყიდვა",
    "SHELF_LOT_TYPE": "Shelf-ის აუქციონი",
    "COPART_LOT_TYPE": "Copart-ის აუქციონი",
    "IAAI_LOT_TYPE": "IAAI-ის აუქციონი",
    "BTN_LOT_TYPE": "BNP აუქციონი",
    "LOT_TYPE_LBL": "ტიპი",
    "END_TIME_LBL": "მთავრდება",
    "LOT_LINK_MSG": "ლოტის ბმული",
    "LOT_ID_LBL": "ID"
  },
  "ru": {
    "DIRECT_SALE_LOT_TYPE": "Прямая продажа",
    "SHELF_LOT_TYPE": "Shelf аукцион",
    "COPART_LOT_TYPE": "Copart аукцион",
    "IAAI_LOT_TYPE": "IAAI аукцион",
    "BTN_LOT_TYPE": "BNP аукцион",
    "LOT_TYPE_LBL": "Тип",
    "END_TIME_LBL": "Время окончания",
    "LOT_LINK_MSG": "Ссылка на лот",
    "LOT_ID_LBL": "ID"
  },
  "uk": {
    "DIRECT_SALE_LOT_TYPE": "Прямий продаж",
    "SHELF_LOT_TYPE": "Shelf аукціон",
    "COPART_LOT_TYPE": "Copart аукціон",
    "IAAI_LOT_TYPE": "IAAI аукціон",
    "BTN_LOT_TYPE": "BNP аукціон",
    "LOT_TYPE_LBL": "Тип",
    "END_TIME_LBL": "Час закінчення",
    "LOT_LINK_MSG": "Посилання на лот",
    "LOT_ID_LBL": "ID"
  }
}
</i18n>
