<template>
  <form
    class="search-form"
    novalidate
    @submit.prevent="submit"
  >
    <p class="search-form__lot-id-lbl">
      {{ $t('LOT_ID_LBL') }}
    </p>

    <ui-text
      v-model="lotId"
      class="search-form__lot-id"
      name="transportation-lot-id"
      :placeholder="$t('LOT_ID_PH')"
      :is-error="errorMsg !== ''"
      :is-disabled="isSubmitting"
      @input="errorMsg = ''"
    >
      <span slot="error">
        {{ errorMsg }}
      </span>
    </ui-text>

    <ui-button
      class="search-form__submit-btn"
      type="submit"
      :is-disabled="isSubmitting"
    >
      <template v-if="isTransportationLoading">
        {{ $t('CALCULATING_BTN') }}
      </template>

      <template v-else-if="isSubmitting">
        {{ $t('SEARCHING_BTN') }}
      </template>

      <template v-else>
        {{ $t('SEARCH_BTN') }}
      </template>
    </ui-button>
  </form>
</template>

<script>
import { UiText, UiButton } from '@shelf.network/ui-kit'

import { mapActions } from 'vuex'
import { actions } from '../store/types'

export default {
  name: 'search-form',
  components: {
    UiText,
    UiButton
  },

  data () {
    return {
      lotId: '',
      errorMsg: '',
      isTransportationLoading: false,
      isSubmitting: false
    }
  },

  computed: {
    matchedLotId () {
      return (this.lotId.match(/\d+$/) || [])[0] || ''
    }
  },

  methods: {
    ...mapActions('ui/transportation', {
      loadLotById: actions.LOAD_LOT_BY_ID,
      loadTransportation: actions.LOAD_TRANSPORTATION
    }),

    async submit () {
      this.errorMsg = this.matchedLotId === ''
        ? this.$t('INVALID_LOT_ID_ERROR')
        : ''

      if (this.errorMsg === '') {
        this.isSubmitting = true

        try {
          await this.loadLotById(this.matchedLotId)
        } catch (e) {
          this.errorMsg = this.$t('LOT_NOT_FOUND_ERROR')
          this.isSubmitting = false
          return
        }

        try {
          this.isTransportationLoading = true
          await this.loadTransportation()
        } catch (e) {
          this.errorMsg = this.$t('TRANSPORTATION_ERROR')
        }

        this.isTransportationLoading = false
        this.isSubmitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-form {
  max-width: 30em;
  margin: 2em auto;
  padding: 2em;
  box-shadow: $static-box-shadow;

  &__lot-id {
    margin-top: 0.6em;
  }

  &__submit-btn {
    margin-top: 2em;
    width: 100%;
  }
}
</style>

<i18n>
{
  "en": {
    "LOT_ID_LBL": "Lot ID or Link",
    "LOT_ID_PH": "Type lot ID or paste lot link here",
    "CALCULATING_BTN": "Calculating…",
    "SEARCHING_BTN": "Searching…",
    "SEARCH_BTN": "Search",
    "INVALID_LOT_ID_ERROR": "Invalid lot ID or link",
    "LOT_NOT_FOUND_ERROR": "Cannot find a lot with provided ID",
    "TRANSPORTATION_ERROR": "Cannot load transportation price"
  },
  "ka": {
    "LOT_ID_LBL": "ლოტის ID ან ბმული",
    "LOT_ID_PH": "ჩაწერეთ ლოტის ID ან ბმული",
    "CALCULATING_BTN": "კალკულაცია…",
    "SEARCHING_BTN": "ძიება…",
    "SEARCH_BTN": "ძიება",
    "INVALID_LOT_ID_ERROR": "არასწორი ID ან ბმული",
    "LOT_NOT_FOUND_ERROR": "აღნიშნული ID-ით ლოტი არ მოიძებნა",
    "TRANSPORTATION_ERROR": "ტრანპორტირების ფასი არ მოიძებნა"
  },
  "ru": {
    "LOT_ID_LBL": "ID лота или ссылка",
    "LOT_ID_PH": "Введите ID лота или вставьте ссылку на лот",
    "CALCULATING_BTN": "Расчет...",
    "SEARCHING_BTN": "Поиск...",
    "SEARCH_BTN": "Поиск",
    "INVALID_LOT_ID_ERROR": "Неверный ID лота или ссылка",
    "LOT_NOT_FOUND_ERROR": "Лот с таким ID не найден",
    "TRANSPORTATION_ERROR": "Стоимость транспортировки невозможно загрузить"
  },
  "uk": {
    "LOT_ID_LBL": "ID лота або посилання",
    "LOT_ID_PH": "Введіть ID лоту або вставте посилання на лот",
    "CALCULATING_BTN": "Розрахунок...",
    "SEARCHING_BTN": "Пошук...",
    "SEARCH_BTN": "Пошук",
    "INVALID_LOT_ID_ERROR": "Неправильний ID лоту або посилання",
    "LOT_NOT_FOUND_ERROR": "Лот с таким ID не знайдений",
    "TRANSPORTATION_ERROR": "Вартість транспортування неможливо завантажити"
  }
}
</i18n>
