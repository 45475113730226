<template>
  <div class="transportation-results">
    <ui-button
      class="transportation-results__back-btn"
      fill="none"
      @click="setTransportation({})"
    >
      <ui-icon
        class="transportation-results__back-btn-icon"
        icon="arrow-left"
      />

      <span class="transportation-results__back-btn-text">
        {{ $t('BACK_BTN') }}
      </span>
    </ui-button>

    <div class="transportation-results__fees">
      <p class="transportation-results__fee">
        {{ $t('CONTAINER_FEE_LBL') }}
        <span class="transportation-results__ellipses" />
        <span class="transportation-results__fee-value">
          {{ $fc(transportation.containerFee, transportation.currency) }}
        </span>
      </p>

      <template v-if="feeDetails.w8Fee">
        <p class="transportation-results__fee">
          {{ $t('TOWING_RATE_LBL') }}
          <span class="transportation-results__ellipses" />
          <span class="transportation-results__fee-value">
            {{ $fc(feeDetails.towingRate, transportation.currency) }}
          </span>
        </p>

        <p class="transportation-results__fee">
          {{ $t('SHELF_FEE_LBL') }}
          <span class="transportation-results__ellipses" />
          <span class="transportation-results__fee-value">
            {{ $fc(feeDetails.shelfFee, transportation.currency) }}
          </span>
        </p>

        <p class="transportation-results__fee">
          {{ $t('W8_FEE_LBL') }}
          <span class="transportation-results__ellipses" />
          <span class="transportation-results__fee-value">
            {{ $fc(feeDetails.w8Fee, transportation.currency) }}
          </span>
        </p>

        <p class="transportation-results__fee">
          {{ $t('BANK_TRANSFER_FEE_LBL') }}
          <span class="transportation-results__ellipses" />
          <span class="transportation-results__fee-value">
            {{ $fc(feeDetails.bankTransferFee, transportation.currency) }}
          </span>
        </p>
      </template>

      <p class="transportation-results__fee transportation-results__fee_total">
        {{ $t('TOTAL_FEE_LBL') }}
        <span
          class="transportation-results__ellipses
                 transportation-results__ellipses_total"
        />
        <span class="transportation-results__fee-value">
          {{ $fc(totalTransportationFee, transportation.currency) }}
        </span>
      </p>
    </div>

    <lot-details />
    <transportation-points />
  </div>
</template>

<script>
import { UiIcon, UiButton } from '@shelf.network/ui-kit'
import LotDetails from './LotDetails'
import TransportationPoints from './TransportationPoints'

import { mapGetters, mapMutations } from 'vuex'
import { mutations } from '../store/types'
import { BROKER_DISCOUNT } from '../store/constants'

import get from 'lodash/get'
import { userGetters } from 'Store/entities/User/types'

export default {
  name: 'transportation-results',
  components: {
    UiIcon,
    UiButton,
    LotDetails,
    TransportationPoints
  },

  computed: {
    ...mapGetters('entities/user', {
      isBroker: userGetters.IS_BROKER,
    }),

    ...mapGetters('ui/transportation', {
      transportation: 'transportation'
    }),

    feeDetails () {
      return get(this.transportation, 'relationships.details', {})
    },

    totalTransportationFee () {
      const convertedBrokerDiscount = this.$cc(
        BROKER_DISCOUNT.amount,
        BROKER_DISCOUNT.currency,
        { to: this.transportation.currency }
      )

      return this.isBroker
        ? this.transportation.totalFee - convertedBrokerDiscount
        : this.transportation.totalFee
    }
  },

  methods: {
    ...mapMutations('ui/transportation', {
      setTransportation: mutations.SET_TRANSPORTATION
    }),
  }
}
</script>

<style lang="scss" scoped>
.transportation-results {
  &__back-btn {
    @include respond-below(sm) {
      margin: 1em 0;
    }

    &-text {
      margin-left: 0.6em;
    }
  }

  &__fees {
    max-width: 30em;
    margin: 0 auto;
    padding: 2em;
    box-shadow: $static-box-shadow;
    border-radius: 5px;
  }

  &__fee {
    line-height: 1.6em;
    display: flex;
    justify-content: space-between;
    font-weight: 400;

    &:not(:first-child) {
      margin-top: 0.4em;
    }

    &_total {
      padding-top: 0.4em;
      font-size: 1.2em;
      font-weight: 700;
    }

    &-value {
      font-weight: 700;
    }
  }

  &__ellipses {
    flex: 1 1 auto;
    margin: 0 0.2em 0.4em;
    border-bottom: dashed 1px $color-ui-secondary;

    &_total {
      border-color: $color-dark;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "BACK_BTN": "Back to Search",
    "CONTAINER_FEE_LBL": "Container Fee",
    "TOWING_RATE_LBL": "Towing Rate",
    "SHELF_FEE_LBL": "Shelf.Network Fee",
    "W8_FEE_LBL": "Conusament",
    "BANK_TRANSFER_FEE_LBL": "Bank Transfer Fee",
    "TOTAL_FEE_LBL": "Total Transportation Price"
  },
  "ka": {
    "BACK_BTN": "ძიება",
    "CONTAINER_FEE_LBL": "კონტეინერის ფასი",
    "TOWING_RATE_LBL": "შიდა ტრანსპორტირება",
    "SHELF_FEE_LBL": "საკომისიო",
    "W8_FEE_LBL": "საკომისიო",
    "BANK_TRANSFER_FEE_LBL": "გადარიცხვა",
    "TOTAL_FEE_LBL": "ტრანსპორტირების ფასი"
  },
  "ru": {
    "BACK_BTN": "Вернуться к поиску",
    "CONTAINER_FEE_LBL": "Плата за контейнер",
    "TOWING_RATE_LBL": "Плата за буксировку",
    "SHELF_FEE_LBL": "Комиссия Shelf.Network",
    "W8_FEE_LBL": "Коносамент",
    "BANK_TRANSFER_FEE_LBL": "Плата за банковский перевод",
    "TOTAL_FEE_LBL": "Общая стоимость перевозки"
  },
  "uk": {
    "BACK_BTN": "Повернутися до пошуку",
    "CONTAINER_FEE_LBL": "Плата за контейнер",
    "TOWING_RATE_LBL": "Плата за буксирування",
    "SHELF_FEE_LBL": "Комісія Shelf.Network",
    "W8_FEE_LBL": "Коносамент",
    "BANK_TRANSFER_FEE_LBL": "Плата за банківський переказ",
    "TOTAL_FEE_LBL": "Загальна вартість перевезення"
  }
}
</i18n>
